body.dark-mode {
  color: #ffffff;
  background-color: #161616;

  .dark-mode-toggle {
    & > button {
      color: #ffe600;
    }
  }

  #page-content-wrapper {
    /* min-width: 100vw; */
    background-color: #161616;
  }

  .custom-header {
    background-color: #1d1d1d;
  }

  a {
    color: inherit;
    text-decoration: none;
    cursor: pointer;
  }

  a.active {
    color: #ffffff;

    svg {
      color: #ffffff !important;
      stroke: #ffffff !important;
    }
  }

  a:hover {
    color: #ffffff !important;
    text-decoration: none;

    svg {
      color: #ffffff !important;
      stroke: #ffffff !important;
    }
  }

  textarea {
    background-color: #2d2d2d;
    color: #ffffff;
  }

  .alert-primary,
  .alert-success,
  .alert-danger,
  .alert-warning,
  .alert-info {
    background-color: #1d1d1d;
    box-shadow: 0px 8px 16px 0px #00000025;
  }

  .btn-primary {
    color: #fff;
  }

  // .btn-secondary {
  //   background-color: #2d2d2d;
  //   border-color: #2d2d2d;
  // }

  // .btn-secondary:hover,
  // .btn-secondary:active {
  //   background-color: #3d3d3d;
  //   border-color: #3d3d3d;
  // }

  .btn-light {
    color: #ffffff;
    background-color: #161616;
  }

  .btn-light:hover {
    background-color: #161616;
  }
  .btn-light:not(:disabled):not(.disabled).active,
  .btn-light:not(:disabled):not(.disabled):active,
  .show > .btn-light.dropdown-toggle {
    color: #ffffff;
    background-color: #1d1d1d;
    border-color: none;
  }

  .bg-dark {
    background-color: #1d1d1d !important;
    background-color: #1d1d1d !important;
  }

  .bg-dark-primary {
    background-color: #2d2d2d;
  }

  .bg-dark-secondary {
    background-color: #161616;
  }

  .bg-black {
    background-color: #000000 !important;
    background-color: #000000 !important;
  }

  .sidebar-nav-item {
    color: #818182;
  }

  .profile-page {
    .nav-tabs {
      .nav-item.show .nav-link,
      .nav-link.active {
        color: #fff;
        background-color: #1d1d1d;
      }

      .nav-link {
        color: #818181;
      }
    }
  }

  .custom-top-nav {
    border-bottom: 1px solid #302f34;

    .nav-item.show .nav-link,
    .nav-link.active {
      color: #ffffff;
      border-bottom: 5px solid #2d2d2d;
    }
  }

  .navbar-light .navbar-nav .nav-link {
    color: #ffffff;
  }

  .form-control {
    background-color: #2d2d2d;
    border: 1px solid #2d2d2d;

    color: #ffffff;
  }

  .form-control:disabled,
  .form-control[readonly] {
    background-color: #181818;
    border: 1px solid #181818;
  }

  .form-control:focus {
    background-color: #2d2d2d !important;
    border: 1px solid #2d2d2d;

    color: #ffffff;
  }

  .radio {
    input {
      & + span {
        &:before {
          background: #3d3d3d;
        }
        &:after {
          background: #2d2d2d;
        }
      }
      &:checked + span {
        &:before {
          background: #4d8af0;
        }
      }
    }
  }

  .input-group-text {
    background-color: #2d2d2d !important;
    border: 1px solid #2d2d2d;
  }

  .pass-wrapper {
    i {
      color: #4d4d4d;
    }
    i:hover {
      color: #8d8d8d;
      // cursor: pointer;
    }
  }

  .text-dark {
    color: #ffffff !important;
  }

  .text-muted {
    color: #7d7d7d;
  }

  hr {
    border-top: 1px solid #302f34;
  }

  .border-bottom {
    border-bottom: 1px solid #2d2d2d !important;
  }

  .border-top {
    border-top: 1px solid #2d2d2d !important;
  }

  .border-right {
    border-right: 1px solid #302f34 !important;
  }

  .border-dark {
    border: 1px solid #2d2d2d !important;
  }

  // .wallpaper-image:hover {
  //   box-shadow: 0px 0px 15px 5px #00000049;
  // }

  .thumb-vertical {
    background-color: #2d2d2d;
  }
  .thumb-vertical:hover,
  .thumb-vertical:focus,
  .thumb-vertical:active {
    background-color: #3d3d3d;
  }

  .slash:after {
    background-color: #fff;
  }

  .upload-description {
    textarea {
      background: transparent;
    }
  }

  /* ---------------------------------- Card ---------------------------------- */

  .list-group-item {
    background-color: #1d1d1d;
    border: 1px solid #2d2d2d;
  }
  .list-group-item:hover {
    background-color: #222222;
  }

  .card {
    background-color: #1d1d1d;
    box-shadow: 0px 0px 15px -10px #000000d6;

    .card-header {
      background-color: #1a1a1a;
      border-bottom: 1px solid #2d2d2d;
    }
  }

  .wallpaper-card {
    background-color: #1d1d1d;
    box-shadow: 0px 0px 15px -10px #000000d6;
  }

  /* -------------------------------- DropDown -------------------------------- */

  .dropdown-menu {
    background-color: #2d2d2d;
    color: #ffffff;
    min-width: 12rem;

    border-top: none;
    border-color: #2d2d2d;

    .dropdown-item {
      color: #ffffff;
    }

    .dropdown-item.disabled,
    .dropdown-item:disabled {
      color: #7d7d7d;
    }

    .dropdown-divider {
      border-top: 1px solid #3d3d3d;
    }

    .dropdown-item:focus,
    .dropdown-item:hover {
      color: #ffffff;
      background-color: #3d3d3d;
    }
  }

  /* -------------------------------- Progress -------------------------------- */

  .progress {
    background-color: #2d2d2d;
  }

  /* --------------------------------- Images --------------------------------- */

  /* ------------------------------ Profile Wallpapers ----------------------------- */

  .profile-wallpapers {
    img {
      box-shadow: 0 1px 0 rgba(0, 0, 0, 0.2);
    }

    .overlay {
      background-color: rgba(0, 0, 0, 0.6);
    }

    .overlay-content {
      color: #fff;
    }

    svg {
      fill: #fff;
    }
  }

  /* ---------------------------------- Modal --------------------------------- */
  .modal {
    .modal-content {
      background-color: #1d1d1d;
      box-shadow: 0px 0px 10px 7px #00000052;
    }

    .gallery-nav {
      color: #cecece;
      background-color: rgba(0, 0, 0, 0.4);
    }

    .gallery-nav:hover {
      background-color: rgba(0, 0, 0, 1);
    }

    .modal-header {
      border-bottom: 1px solid #2d2d2d;
    }

    .modal-footer {
      border-top: 1px solid #2d2d2d;
    }

    .close {
      color: #ffffff;
    }
  }

  /* -------------------------------- DropZone -------------------------------- */

  .dropzone {
    border-color: #3d3d3d !important;
    background-color: #2d2d2d !important;
    color: #ffffff !important;
  }

  /* ----------------------------- SELECT OPTIONS ----------------------------- */

  .react-select__control {
    background-color: #2d2d2d !important;

    .react-select__single-value {
      color: #ffffff !important;
    }
  }

  .react-select__menu {
    background-color: #2d2d2d !important;
  }

  .react-select__option .active {
    background-color: #000000 !important;
  }

  .react-select__option--is-focused:not(.react-select__option--is-selected) {
    background-color: #3d3d3d !important;
  }

  /* --------------------------------- LOADING -------------------------------- */

  .shimmer {
    // background: linear-gradient(to right, #1d1d1d 8%, #1f1f1f 18%, #1d1d1d 33%);

    background: linear-gradient(to right, #161616 8%, #181818 18%, #161616 33%);
  }

  /* ---------------------------------- TAGS ---------------------------------- */
  .bootstrap-tagsinput {
    background-color: #2d2d2d;
  }
  .bootstrap-tagsinput input {
    color: #fff;
  }

  /* -------------------------------- USER CARD ------------------------------- */
  .dropdown-divider {
    border-top: 1px solid #2d2d2d;
  }

  .user-card {
    &:hover {
      // filter: brightness(70%);
      background-color: #1d1d1d !important;
    }
  }

  /* ------------------------------- AVATAR EDIT ------------------------------ */

  .avatar-upload {
    .avatar-edit input + label {
      background: #2d2d2d;
    }
    .avatar-edit input + label:hover {
      background: #4d4d4d;
      border-color: #4d4d4d;
    }

    .avatar-preview {
      border: 6px solid #2d2d2d;
    }
  }
}
