@font-face {
  font-family: SinkinSans;
  src: url("SinkinSans.otf") format("opentype");
}

* {
  font-family: "Lexend" !important;
}

html,
body {
  padding: 0;
  margin: 0;
  // font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
  //   Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;

  // font-family: "SinkinSans" !important;
  font-family: "Lexend" !important;
  // font-weight: bold !important;
  color: #343434;
  // font-size: 0.95rem;
  font-size: 14px !important;

  background-color: #ffffff;

  height: 100vh;

  // transition: color 0.3s ease-in-out;
  // -webkit-transition: color 0.3s ease-in-out;
}

p {
  margin-bottom: 0;
}

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

a.active {
  color: #343434;

  svg {
    color: #343434 !important;
    stroke: #343434 !important;
  }
}

a:hover {
  color: #343434 !important;
  text-decoration: none;

  svg {
    color: #343434 !important;
    stroke: #343434 !important;
  }
}

i {
  font-size: 1.8em;
}

* {
  box-sizing: border-box;
  // -webkit-transition: background-color 0.3s ease-in-out;
  // transition: background-color 0.3s ease-in-out;
}

textarea {
  background-color: #f0f2f5;
  border: none;
  resize: none;
  outline: none;
  border-radius: 4px;
}

.otp-input {
  width: 3rem !important;
  height: 3rem;
  margin: 0 1rem;
  font-size: 2rem;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
}

@media only screen and (max-width: 470px) {
  .otp-input {
    width: 2rem !important;
    height: 2rem;
    margin: 0 5px;
    font-size: 1rem;
  }
}

.is-invalid {
  border: 1px solid #ff5f66;
}

/* ---------------------------- Floating Buttons ---------------------------- */
.float-button-container {
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 2;
}

/* --------------------------------- Toggle --------------------------------- */

.toggle-control {
  position: relative;
  padding: 0 4px;
  display: flex;
  align-items: center;
}
.dark-mode-toggle {
  display: flex;
  // margin: 0 auto;
  // background-color: #ffffff;
  border-radius: 10px;
  height: 36px;

  & > button {
    //  font-size: 1.2em;
    background-color: transparent;
    border: none;
    color: #7d7d7d;
    cursor: pointer;
    transition: color 0.3s ease;
  }
}

$radioSize: 22px;
.radio {
  margin: 16px 0;
  display: block;
  cursor: pointer;
  input {
    display: none;
    & + span {
      line-height: $radioSize;
      height: $radioSize;
      padding-left: $radioSize;
      display: block;
      position: relative;
      &:not(:empty) {
        padding-left: $radioSize + 8;
      }
      &:before,
      &:after {
        content: "";
        width: $radioSize;
        height: $radioSize;
        display: block;
        border-radius: 50%;
        left: 0;
        top: 0;
        position: absolute;
      }
      &:before {
        background: #d1d7e3;
        transition: background 0.2s ease,
          transform 0.4s cubic-bezier(0.175, 0.885, 0.32, 2);
      }
      &:after {
        background: #fff;
        transform: scale(0.78);
        transition: transform 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.4);
      }
    }
    &:checked + span {
      &:before {
        transform: scale(1.04);
        background: #4d8af0;
      }
      &:after {
        transform: scale(0.4);
        transition: transform 0.3s ease;
      }
    }
  }
  &:hover {
    input {
      & + span {
        &:before {
          transform: scale(0.92);
        }
        &:after {
          transform: scale(0.74);
        }
      }
      &:checked + span {
        &:after {
          transform: scale(0.4);
        }
      }
    }
  }
}

input[type="checkbox"].dmcheck {
  width: 40px;
  height: 10px;
  background-color: #5d5d5d;
  position: relative;
  border-radius: 5px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  vertical-align: 2px;
  outline: none;

  &:checked + label {
    left: 30px;
  }

  &:focus-visible {
    outline: solid 2px white;
  }

  & + label {
    display: inline-block;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    transition: all 0.3s ease;
    cursor: pointer;
    position: absolute;
    left: 2px;
    background-color: #c5c5c5;
    // opacity: 0.9;
    margin-bottom: 0;
    background-color: #c5c5c5;
  }
}

/* --------------------------------- RANDOM --------------------------------- */

.sidebar-parent {
  box-shadow: 0px 0px 10px #0000000d;
}

#wrapper {
  // overflow-x: hidden;
}

#sidebar-wrapper {
  width: 350px;
  min-width: 350px;

  margin-left: -350px;
  min-height: 100vh;
  /* margin-left: -17rem; */
  /* display: none !important; */
  transition: margin 0.25s ease-out;
}

.sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
  height: 80px;
}

.sidebar-links {
  /* width: 400px; */
  /* padding: 30px; */
  overflow: auto;
}

#page-content-wrapper {
  /* min-width: 100vw; */
  background-color: #f0f2f5;
}

body.sb-sidenav-toggled #wrapper #sidebar-wrapper {
  margin-left: 0;
}

@media (min-width: 1280px) {
  #sidebar-wrapper {
    margin-left: 0;
    /* display: block !important; */
  }

  #page-content-wrapper {
    min-width: 0;
    width: 100%;
  }

  body.sb-sidenav-toggled #wrapper #sidebar-wrapper {
    margin-left: -15rem;
  }
}

.custom-header {
  background-color: #ffffff;
}

/* ------------------------------------------------------------------- */

/* ------------------------------- RANDOM END ------------------------------- */

.alert {
  padding: 1.25rem 1.25rem;
  border-radius: 4px;
  border: none;
  border-left: 4px solid transparent;
}

.alert-primary {
  color: #4d8af0;
  // background-color: #c9ddff;
  border-color: #4d8af0;
  box-shadow: 0px 8px 16px 0px #5fa4ff26;
}

.alert-success {
  color: #00e093;
  // background-color: #b6fde4;
  border-color: #00e093;
  box-shadow: 0px 8px 16px 0px #5fffd726;
}

.alert-danger {
  color: #ff5f66;
  // background-color: #ffd5d7;
  border-color: #ff5f66;
  box-shadow: 0px 8px 16px 0px #ff5f6626;
}

.alert-warning {
  // color: #ffba3a;
  color: #eaab37;
  // background-color: #fff5e0;
  border-color: #ffba3a;
  box-shadow: 0px 8px 16px 0px #ffd45f26;
}

.alert-info {
  color: #6c61f6;
  // background-color: #e4e2fd;
  border-color: #6c61f6;
  box-shadow: 0px 8px 16px 0px #975fff26;
}

.alert-primary,
.alert-success,
.alert-danger,
.alert-warning,
.alert-info {
  background-color: #ffffff;
}

.btn {
  height: 36px;
  // margin: auto;
  // border-radius: 8px !important;
  // font-size: 14px !important;
  // font-weight: bold !important;
  // padding: 5px 22px 5px 22px !important;
  padding: 5px 22px 5px 22px;
  // margin-left: 8px !important;
  // margin-right: 8px !important;
  white-space: nowrap;

  &:focus,
  &:active {
    outline: none !important;
    box-shadow: none;
  }
}

.btn-primary {
  color: #ffffff;
  border: none;
  box-shadow: #2361cc50 0px 8px 16px 0px;

  background-image: linear-gradient(
    90deg,
    #2361cc,
    #3772d6,
    #448aff,
    #448aff,
    #3772d6,
    #2361cc
  );
  transition: background-position 0.3s ease, box-shadow 0.3s ease-in-out;
  background-size: 200%;
  background-position: left;

  &:hover,
  &:active {
    background-position: right;
    box-shadow: #2361ccbd 0px 6px 12px 0px;
  }

  &:focus,
  &:active {
    outline: none !important;
    box-shadow: #2361cc75 0px 8px 16px 0px !important;
  }
}

// .btn-primary:hover,
// .btn-primary:active {
//   background-position: right;
// }

.btn-secondary {
  background-color: #2d2d2d;
  border-color: #2d2d2d;

  box-shadow: #00000050 0px 8px 16px 0px;

  &:hover,
  &:active {
    background-color: #3d3d3d;
    border-color: #3d3d3d;
  }

  &:focus,
  &:active {
    outline: none !important;
    box-shadow: #0000009c 0px 8px 16px 0px !important;
  }
}

.btn-success {
  color: #ffffff;
  border: none;
  background-image: linear-gradient(
    90deg,
    #2bb865,
    #45c97c,
    #34cf75,
    #32e27b,
    #45c97c,
    #2bb865
  );
  background-size: 200%;
  // background-position: left;

  transition: box-shadow 0.3s ease-in-out;

  box-shadow: #23cc7865 0px 8px 16px 0px;

  &:hover,
  &:active {
    box-shadow: #23cc78a8 0px 6px 12px 0px;
  }

  &:focus,
  &:active {
    outline: none !important;
    box-shadow: #23cc78a8 0px 8px 16px 0px !important;
  }
}

.btn-danger {
  color: #ffffff;
  border: none;
  background-image: linear-gradient(
    90deg,
    #d64835,
    #ff472f,
    #ff5c46,
    #ff5c46,
    #ff472f,
    #d64835
  );
  background-size: 200%;
  // background-position: left;

  transition: box-shadow 0.3s ease-in-out;

  box-shadow: #ff5c4648 0px 8px 16px 0px;

  &:hover,
  &:active {
    box-shadow: #ff5c4685 0px 6px 12px 0px;
  }

  &:focus,
  &:active {
    outline: none !important;
    box-shadow: #ff5c469f 0px 8px 16px 0px !important;
  }
}

// .btn-secondary:hover,
// .btn-secondary:active {
//   background-color: #3d3d3d;
//   border-color: #3d3d3d;
// }

.btn-light {
  color: #343434;
  background-color: #ffffff;
}

.btn-light:hover {
  background-color: #f0f2f5;
}

.br-1 {
  border-radius: 4px !important;
}

.br-2 {
  border-radius: 6px !important;
}

.br-3 {
  border-radius: 8px !important;
}

.br-50 {
  border-radius: 50% !important;
}

.nav-item {
  align-self: center;
}

// .nav-link:hover {
//   color: #7d7d7d !important;
// }

.bg-highlight {
  // background-color: #4489ff2f !important;
  // background-image: linear-gradient();
  background: linear-gradient(#0066ff27, #0084ff25);
  // background-size: 20% 10%;
  padding-left: 3px;
  padding-right: 3px;
  margin-left: -3px;
  margin-right: -3px;
}

.bg-dark {
  background-color: #ffffff !important;
}

.bg-dark-primary {
  background-color: #ffffff;
}

.bg-dark-secondary {
  background-color: #f0f2f5;
}

.bg-black {
  background-color: #000000 !important;
}

.sidebar-nav-item {
  border: none;
  // padding: 0.5rem 1.25rem;
  padding: 0;
  color: #5d5d5d;
  // background-color: #ffffff;
}

.sidebar-nav-item:focus,
.sidebar-nav-item:hover {
  // background-color: transparent;
}

.profile-page {
  .nav-tabs {
    border: none;

    .nav-item.show .nav-link,
    .nav-link.active {
      color: #7d7d7d;
      background-color: #ffffff;
      // border-color: #dee2e6 #dee2e6 #fff;
      border: none;
    }

    .nav-link {
      border: none;
      color: #818181;
    }
    .nav-link:focus,
    .nav-link:hover {
      border: none;
      // background-color: #ffffff;
    }
  }
}

.custom-top-nav {
  font-size: 15px;
  .nav-item {
    .nav-link {
      border: none;
      background-color: transparent;
      border-bottom: 5px solid transparent;
      padding: 0.75rem 1.5rem;
    }
  }

  .nav-item.show .nav-link,
  .nav-link.active {
    border: none;
    background-color: transparent;
    border-bottom: 5px solid #2d2d2d;
    // font-weight: bold;
  }
}

.navbar {
  padding: 0;

  .collapsing {
    -webkit-transition: none;
    transition: none;
    display: none;
  }

  .navbar-collapse.show {
    position: absolute;
    top: 80px;
    right: 0;
    /* width: 100%; */
    background: #1d1d1d;
    z-index: 1;
    // padding: 2em;
    border-radius: 0 0 10px 10px;
  }

  // @media (min-width: 768px) and (max-width: 1000px) {
  //   .collapse {
  //     display: none !important;
  //   }
  // }

  .dropdown-menu {
    margin-top: 15px !important;
    border-radius: 0 0 8px 8px;
    // border: 1px solid #ffffff !important;

    // .dropdown-item:focus,
    // .dropdown-item:hover {
    //   color: #343434;
    //   background-color: #f0f2f5;
    // }
  }
}

.navbar-light .navbar-nav .nav-link {
  color: #343434;
}

// .form-group {
//   margin-bottom: 0;
// }

.form-control {
  background-color: #f0f2f5;
  border: 1px solid #f0f2f5;
  // border-radius: 0.65em;
  // height: 48px !important;
  color: #343434;
  height: 100%;
  transition: none;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #dfdfdf;
  border: 1px solid #dfdfdf;
}

.form-control:focus {
  background-color: #f0f2f5 !important;
  border: 1px solid #f0f2f5;
  outline: none;
  box-shadow: none;
  color: #343434;
}

.input-group-text {
  background-color: #f0f2f5 !important;
  border: 1px solid #f0f2f5;

  padding: 0 0 0 0.75em;
}

.custom-input-group {
  .input-group > .custom-file,
  .input-group > .custom-select,
  .input-group > .form-control,
  .input-group > .form-control-plaintext {
    // font-size: 10px;
    font-size: 0.9em;
    padding-left: 0;
  }

  .input-group {
    // border-radius: 1em;
    overflow: hidden;
    font-weight: bolder;
  }
}

.pass-wrapper {
  // position: relative;
  // display: flex;
  // margin-bottom: 14px;
  i {
    // position: absolute;
    // top: 38%;
    // right: 16%;
    cursor: pointer;
    color: #4d4d4d;
  }
  i:hover {
    color: #8d8d8d;
    // cursor: pointer;
  }
}

.form-control.is-valid,
.form-control.is-invalid {
  background-image: none;
  padding-right: inherit;
}

input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none;
}

.text-black {
  color: #000000;
}

.text-muted {
  color: #7d7d7d;
}

.text-primary {
  color: #448aff !important;
  // #2361cc,
  // #3772d6,
  // #448aff,
  // #448aff,
  // #3772d6,
  // #2361cc
}

hr {
  border-top: 1px solid #ececec;
  width: 100%;

  // transition: border-top 0.3s ease-in-out;
}

.border-bottom {
  border-bottom: 1px solid #ececec !important;
}

.border-top {
  border-top: 1px solid #ececec !important;
}

.border-right {
  border-right: 1px solid #ececec !important;
}

.border-dark {
  border: 1px solid #ececec !important;
}

.badge-primary {
  background-image: linear-gradient(90deg, #448aff, #2361cc) !important;
  background-color: #448aff;
}

.tooltip {
  position: relative;
  float: right;
}
.tooltip > .tooltip-inner {
  background-color: #eebf3f;
  padding: 5px 15px;
  color: rgb(23, 44, 66);
  font-weight: bold;
  font-size: 13px;
}
.popOver + .tooltip > .tooltip-arrow {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #eebf3f;
}

.flex-1 {
  flex: 1 !important;
}

#nprogress .bar {
  height: 7px !important;
  // background-color: #448AFF !important;
  background-image: linear-gradient(
    90deg,
    #2361cc,
    #3772d6,
    #448aff,
    #448aff,
    #3772d6,
    #2361cc
  ) !important;
}

#nprogress .spinner-icon {
  border-top-color: #448aff !important;
  border-left-color: #448aff !important;
}

#nprogress .peg {
  box-shadow: 0 0 10px #448aff, 0 0 5px #448aff !important;
}

.mb-6,
.my-6 {
  margin-bottom: 5rem !important;
}

// .wallpaper-image {
//   border-radius: 10px;
//   transition: 0.3s;
// }

// .wallpaper-image:hover {
//   box-shadow: 0px 0px 15px 5px #00000049;
//   transform: scale(
//     1.01
//   ); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
// }

.inner-content {
  // height: calc(100vh - 80px - 30px) !important;
  height: calc(100vh - 80px) !important;

  // overflow-y: scroll;
}

.thumb-vertical {
  position: relative;
  display: block;
  width: 8px;
  // height: 100px;
  cursor: pointer;
  border-radius: inherit;
  background-color: #8d8d8d;
}
.thumb-vertical:hover,
.thumb-vertical:focus,
.thumb-vertical:active {
  background-color: #5d5d5d;
}

.track-vertical {
  position: absolute;
  width: 8px !important;
  display: block !important;
  right: 2px;
  bottom: 2px;
  top: 2px;
  border-radius: 6px;
}

.input-big {
  height: 60px;
  padding: 0.475rem 1.5rem;
}

// .form-control-2 {

// }

.slash:after {
  position: absolute;
  left: 50%;
  top: 50%;
  background-color: #ececec;
  content: "";
  width: 1px;
  height: 50px;
  transform: rotate(20deg);
  margin-top: -15px;
}

@media (max-width: 992px) {
  .slash:after {
    display: none;
  }
}

/* ------------------------------- Like Button ------------------------------ */

.like-button {
  svg {
    cursor: pointer;
    overflow: visible;
    // width: 48px;
    margin: 0px;
  }

  svg #heart {
    transform-origin: center;
    animation: animateHeartOut 0.3s linear forwards;
  }

  svg #main-circ {
    transform-origin: 29.5px 29.5px;
  }

  .checkbox {
    display: none;
  }

  .checkbox:checked + label svg #heart {
    transform: scale(0.2);
    fill: #e2264d;
    animation: animateHeart 0.3s linear forwards 0.25s;
  }

  .checkbox:checked + label svg #main-circ {
    transition: all 2s;
    animation: animateCircle 0.3s linear forwards;
    opacity: 1;
  }

  .checkbox:checked + label svg #heartgroup1 {
    opacity: 1;
    transition: 0.1s all 0.3s;
  }

  .checkbox:checked + label svg #heartgroup1 #heart1 {
    transform: scale(0) translate(0, -30px);
    transform-origin: 0 0 0;
    transition: 0.5s transform 0.3s;
  }

  .checkbox:checked + label svg #heartgroup1 #heart2 {
    transform: scale(0) translate(10px, -50px);
    transform-origin: 0 0 0;
    transition: 1.5s transform 0.3s;
  }

  .checkbox:checked + label svg #heartgroup2 {
    opacity: 1;
    transition: 0.1s all 0.3s;
  }

  .checkbox:checked + label svg #heartgroup2 #heart1 {
    transform: scale(0) translate(30px, -15px);
    transform-origin: 0 0 0;
    transition: 0.5s transform 0.3s;
  }

  .checkbox:checked + label svg #heartgroup2 #heart2 {
    transform: scale(0) translate(60px, -15px);
    transform-origin: 0 0 0;
    transition: 1.5s transform 0.3s;
  }

  .checkbox:checked + label svg #heartgroup3 {
    opacity: 1;
    transition: 0.1s all 0.3s;
  }

  .checkbox:checked + label svg #heartgroup3 #heart1 {
    transform: scale(0) translate(30px, 0px);
    transform-origin: 0 0 0;
    transition: 0.5s transform 0.3s;
  }

  .checkbox:checked + label svg #heartgroup3 #heart2 {
    transform: scale(0) translate(60px, 10px);
    transform-origin: 0 0 0;
    transition: 1.5s transform 0.3s;
  }

  .checkbox:checked + label svg #heartgroup4 {
    opacity: 1;
    transition: 0.1s all 0.3s;
  }

  .checkbox:checked + label svg #heartgroup4 #heart1 {
    transform: scale(0) translate(30px, 15px);
    transform-origin: 0 0 0;
    transition: 0.5s transform 0.3s;
  }

  .checkbox:checked + label svg #heartgroup4 #heart2 {
    transform: scale(0) translate(40px, 50px);
    transform-origin: 0 0 0;
    transition: 1.5s transform 0.3s;
  }

  .checkbox:checked + label svg #heartgroup5 {
    opacity: 1;
    transition: 0.1s all 0.3s;
  }

  .checkbox:checked + label svg #heartgroup5 #heart1 {
    transform: scale(0) translate(-10px, 20px);
    transform-origin: 0 0 0;
    transition: 0.5s transform 0.3s;
  }

  .checkbox:checked + label svg #heartgroup5 #heart2 {
    transform: scale(0) translate(-60px, 30px);
    transform-origin: 0 0 0;
    transition: 1.5s transform 0.3s;
  }

  .checkbox:checked + label svg #heartgroup6 {
    opacity: 1;
    transition: 0.1s all 0.3s;
  }

  .checkbox:checked + label svg #heartgroup6 #heart1 {
    transform: scale(0) translate(-30px, 0px);
    transform-origin: 0 0 0;
    transition: 0.5s transform 0.3s;
  }

  .checkbox:checked + label svg #heartgroup6 #heart2 {
    transform: scale(0) translate(-60px, -5px);
    transform-origin: 0 0 0;
    transition: 1.5s transform 0.3s;
  }

  .checkbox:checked + label svg #heartgroup7 {
    opacity: 1;
    transition: 0.1s all 0.3s;
  }

  .checkbox:checked + label svg #heartgroup7 #heart1 {
    transform: scale(0) translate(-30px, -15px);
    transform-origin: 0 0 0;
    transition: 0.5s transform 0.3s;
  }

  .checkbox:checked + label svg #heartgroup7 #heart2 {
    transform: scale(0) translate(-55px, -30px);
    transform-origin: 0 0 0;
    transition: 1.5s transform 0.3s;
  }

  .checkbox:checked + label svg #heartgroup2 {
    opacity: 1;
    transition: 0.1s opacity 0.3s;
  }

  .checkbox:checked + label svg #heartgroup3 {
    opacity: 1;
    transition: 0.1s opacity 0.3s;
  }

  .checkbox:checked + label svg #heartgroup4 {
    opacity: 1;
    transition: 0.1s opacity 0.3s;
  }

  .checkbox:checked + label svg #heartgroup5 {
    opacity: 1;
    transition: 0.1s opacity 0.3s;
  }

  .checkbox:checked + label svg #heartgroup6 {
    opacity: 1;
    transition: 0.1s opacity 0.3s;
  }

  .checkbox:checked + label svg #heartgroup7 {
    opacity: 1;
    transition: 0.1s opacity 0.3s;
  }

  @keyframes animateCircle {
    40% {
      transform: scale(10);
      opacity: 1;
      fill: #dd4688;
    }

    55% {
      transform: scale(11);
      opacity: 1;
      fill: #d46abf;
    }

    65% {
      transform: scale(12);
      opacity: 1;
      fill: #cc8ef5;
    }

    75% {
      transform: scale(13);
      opacity: 1;
      fill: transparent;
      stroke: #cc8ef5;
      stroke-width: 0.5;
    }

    85% {
      transform: scale(17);
      opacity: 1;
      fill: transparent;
      stroke: #cc8ef5;
      stroke-width: 0.2;
    }

    95% {
      transform: scale(18);
      opacity: 1;
      fill: transparent;
      stroke: #cc8ef5;
      stroke-width: 0.1;
    }

    100% {
      transform: scale(19);
      opacity: 1;
      fill: transparent;
      stroke: #cc8ef5;
      stroke-width: 0;
    }
  }

  @keyframes animateHeart {
    0% {
      transform: scale(0.2);
    }

    40% {
      transform: scale(1.2);
    }

    100% {
      transform: scale(1);
    }
  }

  @keyframes animateHeartOut {
    0% {
      transform: scale(1.4);
    }

    100% {
      transform: scale(1);
    }
  }
}

/* ---------------------------------- Card ---------------------------------- */

.list-group-item {
  background-color: #ffffff;
  border-width: 0 0 1px !important;
  border: 1px solid #ececec;
}
.list-group-item:hover {
  background-color: #f0f2f5;
}

.card {
  border: none;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 0px 10px #0000000f;
  // overflow: hidden;
  // background-color: transparent;
  // width: 100% !important;

  .card-header {
    background-color: #f9fafb;
    padding: 1rem 1.5rem;
    border-bottom: 1px solid #ececec;
  }

  .card-body {
    padding: 1.5rem;
  }
  .form-control {
    height: 48px;
  }
}

.wallpaper-card {
  background-color: #ffffff;
  padding: 2em;
  border-radius: 10px;
  box-shadow: 0px 0px 10px #0000000f;
}

/* -------------------------------- DropDown -------------------------------- */

.dropdown-menu {
  // top: 15px !important;

  background-color: #ffffff;
  border: 1px solid #ececec;
  color: #343434;
  min-width: 12rem;
  border-radius: 8px;

  box-shadow: 0px 6px 12px 2px #00000010;

  .dropdown-item {
    color: #343434;
    margin-top: 0.25em;
    margin-bottom: 0.25em;
  }

  .dropdown-item.active,
  .dropdown-item:active {
    color: #ffffff;
  }

  .dropdown-item.disabled,
  .dropdown-item:disabled {
    color: #c5c5c5;

    svg {
      color: #c5c5c5 !important;
      stroke: #c5c5c5 !important;
    }
  }

  .dropdown-divider {
    border-top: 1px solid #ececec;
  }

  .dropdown-item:focus,
  .dropdown-item:hover {
    color: #343434;
    background-color: #f0f2f5;
  }
}

/* -------------------------------- Progress -------------------------------- */

.progress {
  background-color: #f0f2f5;
}
.progress-bar {
  // background-image: linear-gradient(90deg, #448aff, #2361cc) !important;
  background-color: #448aff;
}

/* --------------------------------- Images --------------------------------- */

.pic {
  border-radius: 8px;
  object-fit: cover;
}

.profile-pic {
  border-radius: 8px;
  object-fit: cover;
  max-width: 256px;
}

.profile-pic-round {
  border-radius: 50%;
  object-fit: cover;
}

// .wallpaper-thumbnail-sm {
//   border-radius: 8px;
//   object-fit: cover;
//   // max-width: 256px;
//   height: 40x;
// }

// .wallpaper-pic {
//   width: 300px;
//   height: 300px;
//   // width: calc(33% - 2rem);
//   // height: 300px;
//   min-width: 30%;

//   //  width: 30%;
//   //  height: auto;
//   box-shadow: 0px 0px 18px -6px #000;
// }

// .wallpaper-pic:hover {
//   cursor: pointer;
//   -webkit-filter: brightness(50%);
//   -webkit-transition: all 0.3s ease;
//   -moz-transition: all 0.3s ease;
//   -o-transition: all 0.3s ease;
//   -ms-transition: all 0.3s ease;
//   transition: all 0.3s ease;
// }

// @media (min-width: 1280px) {
//   .wallpaper-pic {
//     width: 30%;
//   }
// }

/* ------------------------------ Profile Wallpapers ----------------------------- */

.image-gallery {
  .container-overlay {
    position: relative;
    cursor: pointer;
    transition: all 0.1s ease-in-out;

    img {
      border-radius: 4px;
      box-shadow: 0 3px 5px -1px #00000016, 0 5px 8px #00000012,
        0 1px 14px #0000000a;
    }
  }

  .container-overlay:hover {
    transform: scale(1.02);

    .overlay {
      display: block;
    }
  }

  .overlay {
    border-radius: 4px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    // box-shadow: 0 0 50px rgba(0, 0, 0, 0.75) inset !important;
    z-index: 4;
    display: none;

    .overlay-content {
      display: flex;
      // justify-content: center;
      // align-items: center;
      height: 100%;
      color: #fff;
      font-weight: 500;
      font-size: 1.1rem;

      span {
        padding-right: 0.5rem;

        svg {
          // fill: #fff;
          position: relative;
        }
      }

      span:first-child {
        margin-right: 1rem;
      }
    }
  }
}

.category-gallery {
  color: #ffffff !important;
  .category-container {
    cursor: pointer;
    background-color: #2d2d2d;
    border-radius: 2px;
    overflow: hidden;
  }

  .category-container:hover {
    // box-shadow: 0 3px 5px -1px #00000033, 0 5px 8px #00000024,
    //   0 1px 14px #00000015;
    box-shadow: 0 0 50px rgba(0, 0, 0, 0.35) inset !important;
  }
}

/* ---------------------------------- Modal --------------------------------- */

.modal {
  .modal-content {
    background-color: #ffffff;
    box-shadow: 0px 0px 10px 7px #00000052;
    border-radius: 6px;
    overflow: hidden;
    width: auto;
    max-width: calc(100% - 300px);
    margin-left: auto;
    margin-right: auto;
  }

  .gallery-nav-arrow {
    top: calc(50vh - 30px);
  }

  .modal-body {
    padding: 0;
    // max-height: 90vh;
  }

  .modal-inner-body {
    display: flex;
    flex-direction: row;
    height: 90vh;

    .wallpaper-container {
      display: flex;
      align-items: center;
      background-color: #000000;

      .wallpaper-container-inner {
        width: 100%;
        position: relative;

        .wallpaper-image {
          object-fit: contain;
        }
      }
    }
    .wallpaper-description {
      // min-width: 350px;
      // width: 360px;

      min-width: 360px;
      width: 25%;
    }
  }

  .gallery-nav {
    padding: 0;
    border-radius: 4px;
    // height: 60px;
    border: none;
    color: #cecece;
    background-color: rgba(0, 0, 0, 0.4);
    opacity: 1;
    z-index: 2;
  }

  .gallery-nav:hover {
    opacity: 1;
    background-color: rgba(0, 0, 0, 1);
  }

  button:disabled {
    opacity: 0.2;
  }
  button:hover:disabled {
    opacity: 0.2;
  }
}

@media (max-width: 1024px) {
  .modal {
    overflow: hidden !important;
  }

  .gallery-nav-arrow {
    // top: calc(25vh - 30px) !important;
    top: calc(15vh - 30px) !important;
  }

  .modal-content {
    border-radius: 0 !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    max-width: none !important;
    width: 100% !important;
  }

  .modal-inner-body {
    flex-direction: column !important;
    height: 100vh !important;

    .wallpaper-container {
      // height: 50%;
      // min-height: 50%;
      height: 30%;
      min-height: 30%;

      .wallpaper-container-inner {
        height: 100%;
        display: flex;
        justify-content: center;
      }
    }
    .wallpaper-description {
      width: 100% !important;
      // height: 50%;
      // height: 50%;
      height: -webkit-fill-available;
    }
  }

  .modal-dialog {
    // max-width: 500px;
    margin: 0;
  }
}

.modal-backdrop.show {
  opacity: 0.8;
}

.modal-xl {
  max-width: none !important;
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: none !important;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: none !important;
  }
}
@media (min-width: 576px) {
  .modal-dialog-centered {
    min-height: none !important;
  }
}
@media (min-width: 576px) {
  .modal-dialog {
    max-width: none !important;
  }
}

.follow-data-modal {
  .modal-content {
    max-width: 475px;
    // min-width: 475px;
  }

  @media (min-width: 1024px) {
    .modal-content {
      min-width: 475px;
    }
  }
}

/* -------------------------------- DropZone -------------------------------- */

.dropzone {
  min-height: 400px;
  border-radius: 10px !important;
  border-color: #eeeeee;
  background-color: #fafafa;
  color: #bdbdbd;
}

.upload-description {
  textarea {
    background: transparent;
    resize: none;
    font-size: 1.5em;
    outline: none;
    border: none;
    width: 100%;
    height: 100%;
    cursor: auto;
  }

  textarea::-webkit-scrollbar {
    width: 10px;
    cursor: pointer;
  }

  textarea::-webkit-scrollbar-track {
    background: #000000;
    border-radius: 4px;
  }

  textarea::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #1d1d1d;
    outline: 1px solid #2d2d2d;
  }
  textarea::-webkit-scrollbar-thumb:hover {
    background-color: #2d2d2d;
  }
}

/* ----------------------------- SELECT OPTIONS ----------------------------- */

.react-select__control {
  background-color: #f0f2f5 !important;
  border: none !important;
  box-shadow: none !important;
  height: 48px;

  .react-select__single-value {
    color: #343434 !important;
  }
}

.react-select__menu {
  background-color: #ffffff !important;
}

// .react-select__menu-list {
//   border: none !important;
// }

.react-select__option .active {
  background-color: #000000 !important;
}

.react-select__option--is-focused:not(.react-select__option--is-selected) {
  background-color: #f0f2f5 !important;
}

.react-select__option--is-selected {
  background-image: linear-gradient(90deg, #2361cc, #448aff) !important;
}

/* -------------------------------- Read More ------------------------------- */

.read-more-button {
  color: #7d7d7d;
  // font-weight: bold;
  cursor: pointer;
}

.read-more-button:hover {
  color: #8d8d8d;
}

/* ---------------------------------- TAGS ---------------------------------- */

.bootstrap-tagsinput .tag {
  background-color: #448aff;
  border-radius: 4px;
  border: none;
  color: #fff;
  display: inline-block;
  font-weight: 400;
  font-family: inherit;
  font-size: 1em;
  margin: 4px;
  padding: 2px 9px;
  box-shadow: #2361cc50 0px 8px 16px 0px;
}
.bootstrap-tagsinput .tag:nth-child(5n + 1) {
  background: #4d8af0;
  box-shadow: #2361cc50 0px 8px 16px 0px;
}
.bootstrap-tagsinput .tag:nth-child(5n + 2) {
  background: #00e093;
  box-shadow: #00b67650 0px 8px 16px 0px;
}
.bootstrap-tagsinput .tag:nth-child(5n + 3) {
  background: #6c61f6;
  box-shadow: #5047cc50 0px 8px 16px 0px;
}
.bootstrap-tagsinput .tag:nth-child(5n + 4) {
  background: #f0825f;
  box-shadow: #c9644550 0px 8px 16px 0px;
}
.bootstrap-tagsinput .tag:nth-child(5n + 5) {
  background: #ff5f66;
  box-shadow: #c73a4150 0px 8px 16px 0px;
}

.bootstrap-tagsinput {
  background-color: #f0f2f5;
  border: none;
  border-radius: 4px;
  overflow: hidden;
  padding: 5px;
}

.react-tagsinput-input {
  width: 100% !important;
  height: 38px; // 5 padding top bottom
  // background: #e5e7eb !important;
  font-family: inherit !important;
  font-size: 1em !important;
  border-radius: 4px;
}
.react-tagsinput--focused {
  border: none !important;
}

/* ------------------------------ NOTIFICATION ------------------------------ */
@mixin horizontalAbsoluteCenter {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.notification-button {
  border: none;
  font-family: inherit;
  background-color: transparent;
  display: flex;
  align-items: center;
  position: relative;
  outline: none;
  height: 100%;
  // margin: 0;
  // margin: 0px 6px;
  // padding: 0;

  &__popup {
    @include horizontalAbsoluteCenter;
    display: flex;
    align-items: center;
    top: 120%;
    // top: 50px;
    padding: 0.5rem 0.85rem;
    border-radius: 10px;
    background-color: #448aff;
    font-size: 1.25rem;
    transform-origin: top left;

    & > *:not(:last-child) {
      margin-right: 1rem;
    }

    & > div {
      display: flex;

      & > * {
        color: #ffffff;
        &:not(:last-child) {
          margin-right: 5px;
        }
      }
    }

    &:before {
      @include horizontalAbsoluteCenter;
      content: "";
      background-color: #448aff;
      width: 2rem;
      height: 1rem;
      bottom: 98%;
      position: absolute;
      clip-path: polygon(50% 0, 100% 100%, 0% 100%);
    }
  }
}

.notification-menu {
  top: 12px !important;

  .notification-inner-container {
    height: 40vh;

    width: 450px;
    max-width: 100vw;
    overflow-y: scroll;

    // @media (max-width: 768px) {
    //   height: calc(100% - 100px);
    // }
  }

  // @media (max-width: 768px) {
  //   margin-top: 0 !important;
  //   // top: 80px !important;
  //   height: calc(100vh - 80px);

  //   .notification-inner-container {
  //     width: calc(100vw - 15px);
  //     min-width: 350px;
  //   }
  // }
}

/* -------------------------------- USER CARD ------------------------------- */
.user-card {
  display: flex;
  align-items: center;
  padding: 0.5rem 1.5rem;
  cursor: pointer;

  &:hover {
    // filter: brightness(70%);
    background-color: #f0f2f5 !important;
  }

  &__details {
    text-align: left;
    margin-left: 1rem;
    line-height: 15px;
    max-width: 70%;
  }

  & > :nth-child(3) {
    margin-left: auto;
  }
}

/* ------------------------------- AVATAR EDIT ------------------------------ */

.avatar-upload {
  position: relative;
  max-width: 205px;
  // margin: 50px auto;

  .avatar-edit {
    position: absolute;
    right: 0px;
    z-index: 1;
    top: 0px;
  }
  .avatar-edit input {
    display: none;
  }
  .avatar-edit input + label {
    display: inline-block;
    // width: 34px;
    // height: 34px;
    margin-bottom: 0;
    border-radius: 8px;
    background: #f8f8f8;
    border: 1px solid transparent;
    // box-shadow: 0px 2px 4px 0px #0000001f;
    cursor: pointer;
    font-weight: normal;
    transition: all 0.2s ease-in-out;
  }
  .avatar-edit input + label:hover {
    background: #f1f1f1;
    // border-color: #d6d6d6;
    // box-shadow: 0px 2px 4px 0px #0000001f;
  }
  .avatar-edit input + label:after {
    // content: "\f040";
    // font-family: "FontAwesome";
    color: #757575;
    position: absolute;
    top: 10px;
    left: 0;
    right: 0;
    text-align: center;
    margin: auto;
  }
  .avatar-preview {
    width: 192px;
    height: 192px;
    position: relative;
    border-radius: 8px;
    border: 6px solid #f8f8f8;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);

    div {
      // background-image: url('');
      width: 100%;
      height: 100%;
      // border-radius: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;

      img {
        border-radius: 0;
      }
    }
  }
}

/* --------------------------- Full Page Wallpaper -------------------------- */

.full-page-wallpaper {
  .modal {
    display: flex;
    position: relative;
    z-index: 0;

    .modal-inner-body {
      height: 100% !important;
    }
  }
  // top: 0;
  // left: 0;
  // z-index: 1050;
  // width: 100%;
  // height: 100%;
  // overflow: hidden;
  // outline: 0;

  // .modal-inner-body {
  //   display: -webkit-flex;
  //   display: flex;
  //   -webkit-flex-direction: row;
  //   flex-direction: row;
  //   height: 90vh;
  // }
}
